@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/colors';

.easterDiscountTeaserDesktop {
    border: 1px solid $marktMaedels;
    border-radius: 5px;
    padding: $global-spacing-smedium;
    position: relative;

    &__withTopMargin {
        margin-top: $landingpage-top-level-element-spacing;
    }

    &__withBottomMargin {
        margin-bottom: $landingpage-top-level-element-spacing;
    }

    &__smallerFont {
        .headline {
            font-size: 19px;
        }

        .subHeadline {
            font-size: 16px;
        }

        .countdown {
            font-size: 16px;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: $global-spacing-small;
    margin-right: 150px;
}

.headline {
    font-size: 23px;
    font-weight: bold;
}

.subHeadline {
    font-size: 20px;
}

.countdown {
    font-size: 20px;
    color: $buckIsBluetiful;
}

.eggImage {
    aspect-ratio: 1.2 / 1;
    background-repeat: no-repeat;
    bottom: $global-spacing-smedium;
    position: absolute;
    right: $global-spacing-smedium;
    top: $global-spacing-smedium;
    background-size: contain;
    background-position: right;
    max-height: 150px;
}
